import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import "./css/normalize.css"
import "./css/global.css"


//ライブラリー
const { ethers } = require("ethers");
const { MerkleTree } = require( 'merkletreejs');
const keccak256 = require( 'keccak256');
//abi,bytecodeを取り込む。srcにリンクを張る。
//const {abi} = require("./FLERC721AUpgradeable.json");
//const {abi} = require("./FLERC721A.json");//FolowerLolita
const {abi} = require("./FLSERC721A.json");

//プロジェクトごとに設定
//main
const contractAddress = "0xf2665D9FB3a0AD17A7454da077BEC9cC159F644A"; //NFTのdeploy時のアドレスを指定する。
const checkChainId = 1;//対応するChainId.  4=rinkeby。1=main polygon=137
//rinkeby
//const contractAddress = "0xC0805270394946686F745c1CB0f942ed2B105a98"; //NFTのdeploy時のアドレスを指定する。
//const checkChainId = 4;//対応するChainId.  4=rinkeby。1=main polygon=137
//local
//const contractAddress = "0x5FbDB2315678afecb367f032d93F642f64180aa3"; //NFTのdeploy時のアドレスを指定する。
//const checkChainId = 31337;//対応するChainId.  4=rinkeby。1=main polygon=137 local=31337



//定数
//const hash0 = ethers.constants.HashZero;

//walletの変更でイベント取りたい
const providerFirst = new ethers.providers.Web3Provider(window.ethereum,"any");

//whitelistを取り込む
const whitelist = require("./whitelist");
//マークルツリーwhitelistを作成。
const leafNodes = whitelist.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
const rootHash = merkleTree.getRoot();//root
//マークルツリーのhexProofを得る
const getHexProof = (address) => {
  const i = whitelist.indexOf(address);
  if(i>=0){
    const claimingAddress = leafNodes[whitelist.indexOf(address)];
    const hexProof = merkleTree.getHexProof(claimingAddress);
    return(hexProof);
  }else{
    return([]);
  }
};

//パラメーター取得
function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

//メイン
const Main = () => {



  const [price,setPrice] = useState(ethers.utils.parseEther("0"));//priceの変数とset関数と初期化.BigNumber
  const [isConnected,setConnected] = useState(false);//isConnectedの変数とset関数と初期化
  const [totalSupply,setTotalSupply] = useState(0);//totalSupplyの変数とset関数と初期化
  const [maxSupply,setMaxSupply] = useState(0);//maxSupplyの変数とset関数と初期化
  const [nftPerAddressLimit,setNftPerAddressLimit] = useState(0);//nftPerAddressLimitの変数とset関数と初期化
  const [onlyWhitelisted,setOnlyWhitelisted] = useState(false);//onlyWhitelistedの変数とset関数と初期化
  const [amount,setAmount] = useState(1);//amountの変数とset関数と初期化.mint数。
  const [canIMint,setCanIMint] = useState(false);//mint可能かの変数とset関数と初期化.
  const [isPaused,setIsPaused] = useState(true);//停止の変数とset関数と初期化.
  const [myAddress,setMyAddress] = useState("");//wallet addressの変数とset関数と初期化.

  //処理
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contractAddress, abi, provider);
  const connect = async() => { await provider.send("eth_requestAccounts", []) };
  const getAddress = async() => { try {
    let address = await signer.getAddress();
    address = address.toLowerCase();//小文字に統一する
    return address;
  } catch { return false; }}; 
  const getChainId = async() => { return await signer.getChainId() };




  //表示の更新
  const updateInfo = async() => {
    //接続チェック
    const address = await getAddress();
    const chainId = await getChainId();
    const isOK = (chainId === checkChainId && address ? true : false);
    if(!isOK){
      setConnected(false);
      setCanIMint(false);
      //console.log("Check Wallet Network.exit.");
      return;
    }
    if(address) setConnected(true);
    else setConnected(false);
    setMyAddress(address);//myAddressセット

    setTotalSupply((await contract.totalSupply()).toString());//totalSupplyをセット
    setMaxSupply((await contract.maxSupply()).toString());//maxSupplyをセット.最大のsupply数
    //Sale状況確認
    const isWlEnabled = await contract.isWlEnabled();
    const isPsEnabled = await contract.isPsEnabled();
    //Saleの状態
    let maxMintNum = 0;
    if(isWlEnabled && !isPsEnabled){//WL Sale
      setPrice(await contract.wlMintPrice());//WL金額をセット
      setOnlyWhitelisted(true);//onlyWhitelistedをセット.WhilteLIstSaleのときにtrue,PublicSaleではfalse
      maxMintNum = await contract.maxMintsPerWL();
      setNftPerAddressLimit(maxMintNum.toString());//nftPerAddressLimitをセット.一人当たりの最大mint数。
      //wlチェック
      const hexProof = getHexProof(address);
      const ret = await contract.isWhitelisted(address,hexProof);
      setCanIMint(ret);//mintできるかどうか
      setIsPaused(false);//Saleしてる
    }else if(isPsEnabled){//Public Sale
      setPrice(await contract.psMintPrice());//PS金額をセット
      setOnlyWhitelisted(false);//onlyWhitelistedをセット.WhilteLIstSaleのときにtrue,PublicSaleではfalse
      maxMintNum = await contract.maxMintsPerPS();
      setNftPerAddressLimit(maxMintNum.toString());//nftPerAddressLimitをセット.一人当たりの最大mint数。
      setCanIMint(true);//mintできる
      setIsPaused(false);//Saleしてる
    }else{
      setIsPaused(true);//Saleしていない
      //wlチェック
      const hexProof = getHexProof(address);
      const ret = await contract.isWhitelisted(address,hexProof);
      setCanIMint(ret);//mintできるかどうか
      
    }
  };
  //Reactのインターバル　
  React.useEffect(function() {
    const intervalId = setInterval(function() {
      updateInfo();
    }, 2000);
    return function(){clearInterval(intervalId)};
  }, [price,isConnected,totalSupply,maxSupply,nftPerAddressLimit,onlyWhitelisted,amount,canIMint,isPaused,myAddress]);
  window.onload = async() => {
    await updateInfo();
  };

  //accountの切り替え監視
  window.ethereum.on("accountsChanged", async (accounts_) => { await updateInfo(); });
  //chainidの切り替え監視
  providerFirst.on("network",async (network, oldNetwork) => {await updateInfo(); });


  //Clickの処理.NFTをmint
  const buttonConnect = async() => {
    await connect();
    const address = await getAddress();
    const chainId = await getChainId();
    const isOK = (chainId === checkChainId && address ? true : false);
    if(!isOK){
      setConnected(false);
      setCanIMint(false);
      alert("Switch to Ethereum mainnet!");
      return;
    }else setConnected(true);
    await updateInfo();

  };
  //Clickの処理.Minus
  const buttonMinus = async() => {
    let amountVal = amount;
    --amountVal;
    if(amountVal < 1) amountVal = 1;
    setAmount(amountVal);
  };
  //Clickの処理.Minus10
  const buttonMinus10 = async() => {
    let amountVal = amount;
    amountVal -= 10;
    if(amountVal < 1) amountVal = 1;
    setAmount(amountVal);
  };
  //Clickの処理.Plus
  const buttonPlus = async() => {
    let amountVal = amount;
    ++amountVal;
    if(amountVal > nftPerAddressLimit) amountVal = nftPerAddressLimit;
    setAmount(amountVal);
  };
  //Clickの処理.Plus10
  const buttonPlus10 = async() => {
    let amountVal = amount;
    amountVal += 10;
    if(amountVal > nftPerAddressLimit) amountVal = nftPerAddressLimit;
    setAmount(amountVal);
  };
  
  //Clickの処理.NFTをmint
  const buttonMint = async() => {
    try{
      const address = await getAddress();
      //署名を付けてコントラクトのmintを実行します。
      const hexProof = getHexProof(address);
      const valuePrice = ethers.BigNumber.from(price).mul(amount);
      let tx;
      if(!isPaused && onlyWhitelisted){
        tx = await contract.connect(signer).whitelistMint(amount,hexProof,{value:valuePrice});//metamaskの署名を要求する
        await tx.wait();
      }else if(!isPaused && !onlyWhitelisted){
        tx = await contract.connect(signer).publicMint(amount,{value:valuePrice});//metamaskの署名を要求する
        await tx.wait();
      }
    }catch(e){
      handleErrTx(e)
    };
  };
  //エラー処理.tx系
  const handleErrTx = async(e) => {
    let message = e.message;
    if(message.indexOf("insufficient funds for") >= 0) message="You have not enough ETH.";
    if(message.indexOf("You have no whitelistMint left") >= 0) message="You have no whitelistMint left.";
    if(message.indexOf("You have no publicMint left") >= 0) message="You have no publicMint left.";
    if(message.indexOf("No more NFT") >= 0) message="No more NFTs.";
    alert("Error : "+message);
  };
 
 
  //ここはJSXの書き方
  return (
    <>
    <p className="img"><img src="./img/Chibi_Lolita_Logo_Shadow.png" alt="" title="" className="crop-default" data-description=""/></p>
    {!isConnected && <p className="btn-typ01"><button id="connect" onClick={buttonConnect}>Connect Wallet</button></p>}
    {isConnected && isPaused && <>
      <p className="ttl">MINT COMING SOON</p>
      {<p className="l-txt">{canIMint ? "You are WhiteListed.":"You are not WhiteListed."}</p>}
      {<p className="address">Whitelist Sale: 18 June, 22:00 (GMT+9)</p>}
      {<p className="address"><br/></p>}
      {<p className="address">*Please refresh the page after connecting your wallet.</p>}
      {<p className="address">Your Wallet address: {myAddress}</p>}
    </>}
    {isConnected && !isPaused && <>
      {<p className="ttl">{onlyWhitelisted ? "NOW WHITELIST SALE":"NOW PUBLIC SALE"}</p>}
      {<p className="total-supply">{totalSupply}/{maxSupply}</p>}
      {parseInt(totalSupply) >= parseInt(maxSupply) &&  <p className="total-supply">Sold Out!</p>}
      {canIMint && 
        <div className="mint-cnt">
        <div className="cnt-block">
        <button id="minus10" onClick={buttonMinus10}>-10</button>
        <button id="minus" onClick={buttonMinus}>-1</button>
        <p className="num">{amount}</p>
        <button id="plus" onClick={buttonPlus}>+1</button>
        <button id="plus10" onClick={buttonPlus10}>+10</button>
        </div>
        <p className="s-txt">max mint: {nftPerAddressLimit}</p>
        <p className="s-txt">price: {ethers.utils.formatEther(price)} ETH</p>
        </div>}      
      {canIMint && <p className="btn-typ03"><button id="mint" onClick={buttonMint}>MINT MY FLOWER LOLITA</button></p>}
      {<p className="address">Your Wallet address: {myAddress}</p>}
    </>}
    </>
  );
}
export default Main;